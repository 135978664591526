<div class="container">
    <h2>My Skills</h2>
    <img [lazyLoad]="'/assets/undraw/skills.svg'" class="skills" />

    <h3>
        <fa-icon [icon]="['fas', 'thumbtack']" slot="start" [fixedWidth]="true"></fa-icon>
        My favorite Frameworks
    </h3>
    <div class="skill-list" fxLayout="row wrap" fxLayoutGap="12px grid">
        <div fxFlex="50%" fxFlex.lt-md="100%" *ngFor="let tile of favFrameworks">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>{{tile.title}}</mat-card-title>
                    <mat-card-subtitle>{{tile.text}}</mat-card-subtitle>
                </mat-card-header>
            </mat-card>
            <mat-progress-bar mode="determinate" [value]="tile.value"></mat-progress-bar>
        </div>
    </div>

    <h3>
        <fa-icon [icon]="['fas', 'language']" slot="start" [fixedWidth]="true"></fa-icon>
        My most used Languages (desc. order)
    </h3>
    <div class="skill-list" fxLayout="row wrap" fxLayoutGap="12px grid">
        <div fxFlex="50%" fxFlex.lt-md="100%" *ngFor="let tile of mostUsedLanguages">
            <mat-card class="mat-elevation-z2">
                <mat-card-header>
                    <mat-card-title>{{tile.title}}</mat-card-title>
                    <mat-card-subtitle>{{tile.text}}</mat-card-subtitle>
                </mat-card-header>
            </mat-card>
            <mat-progress-bar mode="determinate" [value]="tile.value"></mat-progress-bar>
        </div>
    </div>

    <h3>
        <fa-icon [icon]="['fas', 'lightbulb']" slot="start" [fixedWidth]="true"></fa-icon>
        Other Skills
    </h3>
    <div class="skill-list" fxLayout="row wrap" fxLayoutGap="12px grid">
        <div fxFlex="50%" fxFlex.lt-md="100%" *ngFor="let tile of otherSkills">
            <mat-card class="mat-elevation-z2">
                <mat-card-header>
                    <mat-card-title>{{tile.title}}</mat-card-title>
                    <mat-card-subtitle>{{tile.text}}</mat-card-subtitle>
                </mat-card-header>
            </mat-card>
            <mat-progress-bar mode="determinate" [value]="tile.value"></mat-progress-bar>
        </div>
    </div>
</div>
