import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

    email: string;
    phoneNumber: string;

    constructor(private titleService: Title) {
        this.titleService.setTitle('Contact');
    }

    ngOnInit() {
        this.email = 'andreas@bavarianbits.de';
        this.phoneNumber = '+49 1511 7815136';
    }
}
