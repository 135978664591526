import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {

    constructor(private translate: TranslateService,
        private fontLibrary: FaIconLibrary) {
        this.translate.setDefaultLang('en');
        this.translate.use('en');

        this.initFontAwesome();
    }

    private initFontAwesome() {
        this.fontLibrary.addIconPacks(fas, fab);
    }
}
