import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

export interface Tile {
    title: string;
    text: string;
    value: number;
}

@Component({
    selector: 'app-skills',
    templateUrl: './skills.component.html',
    styleUrls: ['./skills.component.scss']
})
export class SkillsComponent {

    favFrameworks: Tile[] = [
        { title: 'Flutter', text: 'Cross Platform Mobile Apps', value: 95 },
        { title: 'Angular', text: 'Modern Web Development', value: 80 },
        { title: 'Spring Boot', text: 'Backend Development', value: 60 },
        { title: 'PixiJS', text: 'Canvas Development', value: 30  },
    ];

    mostUsedLanguages: Tile[] = [
        { title: 'Dart', text: '5 Years of Experience', value: 85 },
        { title: 'TypeScript', text: '7 Years of Experience', value: 90 },
        { title: 'JavaScript', text: '7 Years of Experience', value: 75 },
        { title: 'Java', text: '6 Years of Experience', value: 90 },
        { title: 'Python', text: '1 Years of Experience', value: 25 },
        { title: 'PHP', text: '2 Years of Experience', value: 50 },
        { title: 'C#', text: '0.5 Years of Experience', value: 25 },
        { title: 'R', text: '0.25 Years of Experience', value: 25 },
    ];

    otherSkills: Tile[] = [
        { title: 'Creativity/Design', text: 'Designed several hotel brochures, flyers and websites', value: 90 },
        { title: 'Firebase/GCP', text: 'Use of GCP through the complete Firebase ecosystem and beyond', value: 90 },
        { title: 'Docker', text: 'Orchestrate Professional Software Networks', value: 90 },
        { title: 'Node.js', text: 'Building backend endpoints', value: 75 },
        { title: 'NoSQL', text: 'With Cloud Firestore Database', value: 90 },
        { title: 'SQL', text: 'With MySQL, MariaDB, OracleSQL, MSSQL', value: 80 },
        { title: 'Amazon Webservices', text: 'Amazon RDS', value: 25 },
        { title: 'MQTT', text: 'Powering IoT with MQTT', value: 90 },
        { title: 'Laravel Lumen', text: 'PHP REST-API Backends', value: 55 },
        { title: 'Webdevelopment', text: 'HTML(5), CSS(3), JS', value: 80 },
        { title: 'Wordpress', text: 'To create websites', value: 75 },
        { title: 'LDAP', text: 'Authentication via Spring Boot', value: 30 },
        { title: 'Linux', text: 'Server management and scripts', value: 60 },
    ];

    constructor(private titleService: Title) {
        this.titleService.setTitle('Skills');
    }
}
