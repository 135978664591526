<div class="container">
    <div class="start">
        <img [lazyLoad]="'/assets/profilbild_rahmen_small.webp'" width="130" height="130" class="online-cv" />

        <h2 [innerHTML]="'PAGES.START.TITLE' | translate"></h2>
        <p id="intro"></p>
        <div class="links">
            <button mat-button routerLink="/skills">Skills</button>
            <button mat-button routerLink="/apps">Projects</button>
            <button mat-button routerLink="/resume">Résumé</button>
            <button mat-button routerLink="/contact">Contact</button>
        </div>
    </div>
</div>
