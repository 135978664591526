<div class="container">
    <h2>Design Projects</h2>
    <img [lazyLoad]="'/assets/undraw/designs.svg'" class="designs" />

    <h3>
        <fa-icon [icon]="['fas', 'paint-brush']" slot="start" [fixedWidth]="true"></fa-icon>
        Always been there
    </h3>

    <p>
        I have always been interested in modern designs.<br /><br />
        Already at the age of 13 I sold my first graphics to well-known YouTuber and thus
        improved my pocket money.<br /><br />
        Back then in school I was always the first contact person for e.g. party flyers and posters.<br />
        Furthermore I have already designed several hotel brochures and flyers.<br /><br />
        This has not changed until now to my studies. I love designs and still regularly design graphics for companies
        and my own apps/projects.
    </p>

    <h3>
        <fa-icon [icon]="['fas', 'book-open']" slot="start" [fixedWidth]="true"></fa-icon>
        Small examples
    </h3>

    <mat-card>
        <img mat-card-image [lazyLoad]="'/assets/design_example_info_brochure_fortuna.jpg'" alt="Hotel Fortuna, Bad Gögging">
        <mat-card-content>
            <h3>Hotel Fortuna: Info brochure</h3>
            <p>
                Here is a small example of my design work. This is an info brochure I made for a hotel in Bad Gögging.
                The flyer is in the rooms of the hotel.
            </p>
        </mat-card-content>
        <mat-card-actions>
            <a href="/assets/documents/info_brochure_fortuna.pdf" target="_blank" mat-button>
        <fa-icon [icon]="['fas', 'external-link-alt']" slot="start" [fixedWidth]="true"></fa-icon>
        PREVIEW</a>
        </mat-card-actions>
    </mat-card>

    <div class="mat-card-spacer"></div>

    <mat-card>
        <img mat-card-image [lazyLoad]="'/assets/design_example_absperrgitter_ride_with_me.webp'" alt="Hotel Fortuna, Bad Gögging">
        <mat-card-content>
            <h3>Ride With Me: Barrier banner</h3>
            <p>
                In addition to planning and developing my Ride With Me app,
                I obviously had to produce a lot of promotional material for it.
                Among other things, I created this banner, which was printed on
                tarpaulins to be hung out at motorcycle festivals.
            </p>
        </mat-card-content>
    </mat-card>

    <p class="subheader">
        Interested in my design projects? Feel free to
        <a routerLink="/contact">contact me!</a>
    </p>
</div>
