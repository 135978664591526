<div class="container">
    <h2>Résumé</h2>

    <img [lazyLoad]="'/assets/undraw/online-cv.svg'" class="resume" />

    <h3>
        <fa-icon [icon]="['fas', 'download']" slot="start" [fixedWidth]="true"></fa-icon>
        Curriculum Vitae (CV)
    </h3>
    <mat-list>
        <div mat-subheader>Downloads</div>
        <a href="/assets/documents/cv_andreas_huber_en_260824.pdf" target="_blank">
            <mat-list-item>
                <mat-icon mat-list-icon>insert_drive_file</mat-icon>
                <span style="float: right;">🇺🇸</span>
                <div mat-line>English (CV)</div>
                <div mat-line>Last updated 26.08.2024</div>
            </mat-list-item>
        </a>

        <mat-divider></mat-divider>
        <a href="/assets/documents/cv_andreas_huber_de_260824.pdf" target="_blank">
            <mat-list-item>
                <mat-icon mat-list-icon>insert_drive_file</mat-icon>
                <span style="float: right;">🇩🇪</span>
                <div mat-line>German (Lebenslauf)</div>
                <div mat-line>Last updated 26.08.2024</div>
            </mat-list-item>
        </a>

    </mat-list>
</div>
