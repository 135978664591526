import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StartComponent } from './start/start.component';
import { SkillsComponent } from './skills/skills.component';
import { AppsComponent } from './apps/apps.component';
import { DesignsComponent } from './designs/designs.component';
import { ContactComponent } from './contact/contact.component';
import { ResumeComponent } from './resume/resume.component';

const routes: Routes = [
    {
        path: 'about-me',
        component: StartComponent
    },
    {
        path: 'skills',
        component: SkillsComponent
    },
    {
        path: 'apps',
        component: AppsComponent,
    },
    {
        path: 'designs',
        component: DesignsComponent
    },
    {
        path: 'resume',
        component: ResumeComponent
    },
    {
        path: 'contact',
        component: ContactComponent
    },
    {
        path: '**',
        redirectTo: 'about-me'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
