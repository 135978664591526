<div class="container">
    <h2>Projects</h2>
    <img [lazyLoad]="'/assets/undraw/apps.svg'" class="apps" />

    <h3>
        <fa-icon [icon]="['fas', 'warning']" slot="start" [fixedWidth]="true"></fa-icon>
        Warning
    </h3>
    <p>
        In order to avoid redundant information, from now on I will only maintain my project list on the platforms on
        which I am active.
        <br /><br />
        Primarily this is on the website of <a href="https://bavarianbits.de" target="_blank">BavarianBits GmbH</a>, a
        software company for customized software where I am the
        managing director.
    </p>

    <p>Always up-to-date:</p>
    <mat-list>
        <a href="https://www.gulp.de/gulp2/g/spezialisten/profil/hmvfukhkuy" target="_blank" mat-list-item>
            <mat-icon matListIcon>launch</mat-icon>
            <h3 matLine>GULP (randstad professional)</h3>
            <p matLine>
                <span>Platform for freelancers</span>
            </p>
        </a>
        <a href="https://www.freelancermap.de/profil/andreas-huber" target="_blank" mat-list-item>
            <mat-icon matListIcon>launch</mat-icon>
            <h3 matLine>Freelancermap</h3>
            <p matLine>
                <span>Platform for freelancers</span>
            </p>
        </a>

        <a href="https://bavarianbits.de" target="_blank" mat-list-item>
            <mat-icon matListIcon>launch</mat-icon>
            <h3 matLine>BavarianBits GmbH</h3>
            <p matLine>
                <span>My own company</span>
            </p>
        </a>
    </mat-list>


    <p class="subheader">
        Interested in my projects? Feel free to
        <a routerLink="/contact">contact me!</a>
    </p>
</div>
