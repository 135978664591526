<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport
        [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
        [mode]="(isHandset$ | async) ? 'over' : 'side'"
        [opened]="(isHandset$ | async) === false">
        <mat-toolbar>
            <a routerLink="/" class="brand">
                NDHBR<span class="domain">.de</span><br />
                <span class="subtitle">Software Engineering</span>
            </a>
            <ul class="social-media">
                <li>
                    <a href="https://www.linkedin.com/in/a-huber" target="_blank">
                        <fa-icon [icon]="['fab', 'linkedin']" [fixedWidth]="true"></fa-icon>
                    </a>
                </li>
                <li>
                    <a href="https://github.com/ndhbr" target="_blank">
                        <fa-icon [icon]="['fab', 'github']" [fixedWidth]="true"></fa-icon>
                    </a>
                </li>
                <li>
                    <a href="mailto:{{email}}" target="_blank">
                        <fa-icon [icon]="['fas', 'envelope']" [fixedWidth]="true"></fa-icon>
                    </a>
                </li>
            </ul>
        </mat-toolbar>

        <mat-nav-list>
            <a mat-list-item routerLink="/about-me"
            routerLinkActive="active-list-item">
                About Me
            </a>
            <h3 mat-subheader>Experience</h3>
            <mat-divider></mat-divider>
            <a mat-list-item routerLink="/skills"
            routerLinkActive="active-list-item">
                Skills
            </a>
            <a mat-list-item routerLink="/apps"
            routerLinkActive="active-list-item">
                Projects
            </a>
            <h3 mat-subheader>Let's get serious</h3>
            <mat-divider></mat-divider>
            <a mat-list-item routerLink="/resume"
            routerLinkActive="active-list-item">
                Résumé
            </a>
            <a mat-list-item routerLink="/contact"
            routerLinkActive="active-list-item">
                Contact
            </a>
        </mat-nav-list>

    </mat-sidenav>
    <mat-sidenav-content>
        <button
            type="button"
            aria-label="Toggle sidenav"
            (click)="drawer.toggle()"
            *ngIf="isHandset$ | async"
            class="toggle-button"
            mat-icon-button
            color="primary">
            <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>

        <main [@fadeAnimation]="o.isActivated ? o.activatedRoute : ''">
            <router-outlet #o="outlet"></router-outlet>
        </main>
    </mat-sidenav-content>
</mat-sidenav-container>
