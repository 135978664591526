import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import * as Typewriter from 'typewriter-effect/dist/core';

@Component({
    selector: 'app-start',
    templateUrl: './start.component.html',
    styleUrls: ['./start.component.scss']
})
export class StartComponent implements OnInit {

    constructor(private titleService: Title) {
        this.titleService.setTitle('About Me');
    }

    ngOnInit() {
        const typewriter = new Typewriter('#intro', {
            delay: 50,
            deleteSpeed: 20,
        });

        typewriter
            .typeString('I am the <strong>Software Engineer</strong> for your next project.')
            .pauseFor(2500)
            .deleteAll()
            .typeString('I founded an app with <strong>100,000 Downloads</strong> when I was only <strong>20</strong>.')
            .pauseFor(2500)
            .deleteAll()
            .typeString('Select a menu item to learn more about me ⤵️')
            .stop()
            .start();
    }

}
