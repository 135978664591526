import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-designs',
    templateUrl: './designs.component.html',
    styleUrls: ['./designs.component.scss']
})
export class DesignsComponent {

    constructor(private titleService: Title) {
        this.titleService.setTitle('Designs');
    }
}
