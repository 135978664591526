import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgModule } from '@angular/core';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './shared/material/material.module';
import { MainNavComponent } from './main-nav/main-nav.component';
import { StartComponent } from './start/start.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { SkillsComponent } from './skills/skills.component';
import { ContactComponent } from './contact/contact.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppsComponent } from './apps/apps.component';
import { DesignsComponent } from './designs/designs.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { ResumeComponent } from './resume/resume.component';
import { FlexLayoutModule } from '@angular/flex-layout';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        AppComponent,
        MainNavComponent,
        StartComponent,
        SkillsComponent,
        ContactComponent,
        AppsComponent,
        DesignsComponent,
        ResumeComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MaterialModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        FontAwesomeModule,
        LazyLoadImageModule,
        FlexLayoutModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
